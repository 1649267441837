<template>
  <div>
    <q-form ref="editForm">
      <!-- 기본정보 -->
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-appr-btn 
              ref="appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="approvalBudgetPlan"
              @callbackApprAction="approvalBudgetPlanCallback"
              @requestAfterAction="getDetail"
            />
            <!-- 삭제 -->
            <c-btn 
              v-if="editable && !disabled && isOld" 
              label="LBLREMOVE" 
              icon="delete"
              @btnClicked="deleteData" />
            <!-- 저장 -->
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="budgetPlan"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-plant 
              required
              :disabled="disabled||isOld"
              :editable="editable"
              name="plantCd" 
              v-model="budgetPlan.plantCd"
              @datachange="getItems" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 예산년도 -->
            <c-datepicker
              required
              :disabled="disabled||isOld"
              :editable="editable"
              label="LBL0010320"
              name="budgetYear"
              type="year"
              default="today"
              v-model="budgetPlan.budgetYear"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <!-- 총 예산금액 -->
            <c-text
              :disabled="true"
              :editable="editable"
              type="number"
              suffix="원"
              label="LBL0010324"
              name="budgetAllAmt"
              v-model="budgetPlan.budgetAllAmt"
            />
          </div>
        </template>
      </c-card>
      <!-- 항목별 예산편성 -->
      <c-table
        ref="table"
        class="q-mt-sm"
        title="LBL0010325"
        checkboxDisabled="checkboxDisabled"
        :columns="grid.columns"
        :data="budgetPlan.planItems"
        :gridHeight="grid.height"
        :editable="editable&&!disabled"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        @table-data-change="tableDataChange"
        rowKey="budgetYearPlanItemId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable && !disabled" label="직접추가" icon="add" @btnClicked="addItemKeyin" />
            <c-btn v-if="editable && !disabled" label="추가" icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable && !disabled" label="제외" icon="remove" @btnClicked="deleteItem" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='budgetItemName'">
            <c-text-column budgetItemName
              v-if="props.row.imprFlag!=='Y'"
              :editable="editable"
              :disabled="disabled"
              :col="col"
              :props="props"
              @datachange="datachange(props)"
            />
            <span v-else>
              {{props.row[col.name]}}
            </span>
          </template>
        </template>
      </c-table>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'budget-year-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        budgetYearPlanId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      budgetPlan: {
        budgetYearPlanId: '',
        budgetAllAmt: 0,
        plantCd: '',
        budgetYear: '',
        budgetStatusCd: '',
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        planItems: [],
        deletePlanItems: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'budgetTypeName',
            field: 'budgetTypeName',
            label: '구분',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            required: true,
            name: 'budgetItemName',
            field: 'budgetItemName',
            label: '항목',
            align: 'left',
            style: 'width: 250px',
            sortable: false,
            type: 'custom'
          },
          { 
            required: true,
            name: 'budgetPlanAmt',
            field: 'budgetPlanAmt',
            // 예산 사용금액(원)
            label: 'LBL0010326',
            align: 'right',
            style: 'width: 150px',
            sortable: false,
            type: 'number'
          },
          {
            name: 'budgetDetails',
            field: 'budgetDetails',
            // 비고
            label: 'LBLREMARK',
            style: 'width: 300px',
            align: 'left',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'attach',
            field: 'attach',
            // 항목별 첨부
            label: 'LBL0010327',
            align: 'center',
            style: 'width: 250px',
            type: 'attach',
            taskClassCd: 'SAI_BUDGET_ITEM',
            keyText: 'budgetYearPlanItemId',
            sortable: false,
          },
        ],
        height: '500px'
      },
      mappingType: 'PUT',
      detailUrl: '',
      checkUrl: '',
      deleteUrl: '',
      saveUrl: '',
      listItemUrl: '',
      approvalUrl: '',
      editable: true,
      isSave: false,
      isApproval: false,
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.budgetYearPlanId)
    },
    disabled() {
      return this.isOld
        && (this.budgetPlan.budgetStatusCd === 'BS00000005'
          || this.budgetPlan.approvalStatusCd === 'ASC0000001')
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.budgetPlan.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.budgetPlan.approvalStatusCd, 
        apprEditable: this.isOld 
          && this.budgetPlan.budgetStatusCd !== 'BS00000005', // 결재버튼 활성화 기준
        param: this.budgetPlan, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000022', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          budgetYearPlanId: this.budgetPlan.budgetYearPlanId,
          isApprContent: true
        },
        approvalRequestName: `${this.budgetPlan.budgetYear}년 연간예산편성 보고`, // 결재요청명 (문서 title)
        approvalConnId: this.budgetPlan.budgetYearPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 150
      if (height < 500) {
        height = 500
      }
      this.grid.height = String(height) + 'px'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.budget.year.get.url
      this.listItemUrl = selectConfig.sai.budget.item.list.url;
      this.checkUrl = selectConfig.sai.budget.year.check.url
      this.saveUrl = transactionConfig.sai.budget.year.update.url
      this.deleteUrl = transactionConfig.sai.budget.year.delete.url
      this.approvalUrl = transactionConfig.sai.budget.year.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.budgetYearPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.budgetYearPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.budgetPlan, _result.data)
          
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.getItems();
      }
    },
    getItems() {
      if (!this.budgetPlan.plantCd) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010026', // 사업장을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.listItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.budgetPlan.plantCd,
          useFlag: 'Y'
        }
        this.$http.request((_result) => {
          this.budgetPlan.planItems = [
            {
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: '',
              budgetItemName: '개선 처리 비용',
              budgetTypeCd: 'SBT0000001',
              budgetTypeName: '3단계판단법',
              budgetPlanAmt: '',
              budgetDetails: '',
              imprFlag: 'Y',
              checkboxDisabled: 'Y',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            },
            {
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: '',
              budgetItemName: '개선 처리 비용',
              budgetTypeCd: 'SBT0000002',
              budgetTypeName: '순회점검',
              budgetPlanAmt: '',
              budgetDetails: '',
              imprFlag: 'Y',
              checkboxDisabled: 'Y',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            },
            {
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: '',
              budgetItemName: '개선 처리 비용',
              budgetTypeCd: 'SBT0000003',
              budgetTypeName: '제안',
              budgetPlanAmt: '',
              budgetDetails: '',
              imprFlag: 'Y',
              checkboxDisabled: 'Y',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            },
            {
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: '',
              budgetItemName: '개선 처리 비용',
              budgetTypeCd: 'SBT0000005',
              budgetTypeName: '기타',
              budgetPlanAmt: '',
              budgetDetails: '',
              imprFlag: 'Y',
              checkboxDisabled: 'Y',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            },
          ]
          this.$_.forEach(_result.data, _item => {
            this.budgetPlan.planItems.push({
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: _item.saiBudgetItemId,
              budgetItemName: _item.budgetItemName,
              budgetTypeCd: _item.budgetTypeCd,
              budgetTypeName: _item.budgetTypeName,
              budgetPlanAmt: '',
              budgetDetails: '',
              imprFlag: 'N',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          })
        },);
      }
    },
    tableDataChange(props, col) {
      this.budgetPlan.budgetAllAmt = 0;
      if (col.name === 'budgetPlanAmt') {
        if (props.row['budgetPlanAmt'] && props.row['budgetPlanAmt'] > 0) {
          this.$_.forEach(this.budgetPlan.planItems, _item => {
            if (_item.budgetPlanAmt && _item.budgetPlanAmt > 0) {
              this.budgetPlan.budgetAllAmt += Number(_item.budgetPlanAmt)
            } else {
              _item.budgetPlanAmt = 0
            }
          })
        }
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addItemKeyin() {
      this.budgetPlan.planItems.splice(0, 0, {
        budgetYearPlanId: this.popupParam.budgetYearPlanId,
        budgetYearPlanItemId: uid(),
        saiBudgetItemId: '',
        budgetItemName: '',
        budgetTypeCd: 'SBT0000005',
        budgetTypeName: '기타',
        budgetPlanAmt: '',
        budgetDetails: '',
        imprFlag: 'N',
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    addItem() {
      this.popupOptions.title = 'LBL0010328';   // 예산편성 항목 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.budgetPlan.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/budget/budgetItemPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopMst;
    },
    closePopMst(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if ( this.$_.findIndex(this.budgetPlan.planItems, {saiBudgetItemId: _item.saiBudgetItemId}) === -1 ) {
            this.budgetPlan.planItems.splice(0, 0, {
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: _item.saiBudgetItemId,
              budgetItemName: _item.budgetItemName,
              budgetTypeCd: _item.budgetTypeCd,
              budgetTypeName: _item.budgetTypeName,
              budgetPlanAmt: '',
              budgetDetails: '',
              imprFlag: 'N',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        });
      }
    },
    deleteItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.budgetPlan.deletePlanItems) this.budgetPlan.deletePlanItems = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.budgetPlan.deletePlanItems, { budgetYearPlanItemId: item.budgetYearPlanItemId }) === -1) {
              this.budgetPlan.deletePlanItems.push(item)
          }
          this.budgetPlan.planItems = this.$_.reject(this.budgetPlan.planItems, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.budgetYearPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.budget.year.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.budget.year.insert.url
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.budgetPlan.planItems)) {
            this.$http.url = this.checkUrl;
            this.$http.type = 'GET';
            this.$http.param = {
              plantCd: this.budgetPlan.plantCd,
              budgetYear: this.budgetPlan.budgetYear,
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
            };
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0010027',  // 동일한 년도의 예산편성이 있습니다.
                  type: 'warning', // success / info / warning / error
                });
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',  // 확인
                  message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.budgetPlan.regUserId = this.$store.getters.user.userId;
                    this.budgetPlan.chgUserId = this.$store.getters.user.userId;

                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            },);
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.budgetYearPlanId = result.data
      this.getDetail();
    },
    approvalBudgetPlan() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.budgetPlan.planItems)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',  // 확인
              message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.budgetPlan.chgUserId = this.$store.getters.user.userId;

                this.isApproval = !this.isApproval
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalBudgetPlanCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>
